/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CopyBoundaryStatisticsPayload
 */
export interface CopyBoundaryStatisticsPayload {
    /**
     * 
     * @type {number}
     * @memberof CopyBoundaryStatisticsPayload
     */
    targetLocalityId: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof CopyBoundaryStatisticsPayload
     */
    targetBoundaryIds: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof CopyBoundaryStatisticsPayload
     */
    sourcePeriodStartingFrom: string;
    /**
     * 
     * @type {string}
     * @memberof CopyBoundaryStatisticsPayload
     */
    sourcePeriodEndingAt: string;
    /**
     * 
     * @type {string}
     * @memberof CopyBoundaryStatisticsPayload
     */
    targetPeriodStartingFrom: string;
    /**
     * 
     * @type {string}
     * @memberof CopyBoundaryStatisticsPayload
     */
    targetPeriodEndingAt: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof CopyBoundaryStatisticsPayload
     */
    trendBoundaryIds?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof CopyBoundaryStatisticsPayload
     */
    trendLocalityId?: number;
}

export function CopyBoundaryStatisticsPayloadFromJSON(json: any): CopyBoundaryStatisticsPayload {
    return CopyBoundaryStatisticsPayloadFromJSONTyped(json, false);
}

export function CopyBoundaryStatisticsPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): CopyBoundaryStatisticsPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'targetLocalityId': json['target_locality_id'],
        'targetBoundaryIds': json['target_boundary_ids'],
        'sourcePeriodStartingFrom': json['source_period_starting_from'],
        'sourcePeriodEndingAt': json['source_period_ending_at'],
        'targetPeriodStartingFrom': json['target_period_starting_from'],
        'targetPeriodEndingAt': json['target_period_ending_at'],
        'trendBoundaryIds': !exists(json, 'trend_boundary_ids') ? undefined : json['trend_boundary_ids'],
        'trendLocalityId': !exists(json, 'trend_locality_id') ? undefined : json['trend_locality_id'],
    };
}

export function CopyBoundaryStatisticsPayloadToJSON(value?: CopyBoundaryStatisticsPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'target_locality_id': value.targetLocalityId,
        'target_boundary_ids': value.targetBoundaryIds,
        'source_period_starting_from': value.sourcePeriodStartingFrom,
        'source_period_ending_at': value.sourcePeriodEndingAt,
        'target_period_starting_from': value.targetPeriodStartingFrom,
        'target_period_ending_at': value.targetPeriodEndingAt,
        'trend_boundary_ids': value.trendBoundaryIds,
        'trend_locality_id': value.trendLocalityId,
    };
}

