import { isEmpty } from 'lodash'
import { DateTime, Interval } from 'luxon'

import { IntervalType } from './types'

const enumToRegexp = (subjectEnum: Record<string, unknown>) => Object.values(subjectEnum).join('|')

export enum OrganizationEditPageTabs {
    scenes = 'scenes',
    modules = 'modules',
    users = 'users',
    localities = 'localities',
    dataPublishers = 'data-publishers',
    devices = 'devices',
}

export enum LocalityEditTabs {
    editInformation = 'edit-information',
    openingHours = 'opening-hours',
    floorplan = 'floorplan',
    scenes = 'scenes',
    users = 'users',
    temporaryMapToFloorplan = 'temporary-map-to-floorplan',
    describeScene = 'describe-scene',
}

export const LOCALITY_OCCUPANCY_DASHBOARD_PATH = '/stay-safe/:id/:lang?/:hideCapacity?'
export const FLOORPLAN_LOCALITY_OCCUPANCY_DASHBOARD_PATH = '/stay-safe-fp/:id/:lang?'

export const RESET_PASSWORD_PATH = '/reset-password/:token'
export const ACCEPT_INVITATION_PATH = '/accept-invitation/:token'
export const REQUEST_PASSWORD_RESET_PATH = '/request-password-reset'

export const VIVIDI_APP = ''

const NUMBER_REGEX = '([0-9]+)'
export const INTERVAL_START_PARAM = `:intervalStart${NUMBER_REGEX}`
export const INTERVAL_END_PARAM = `:intervalEnd${NUMBER_REGEX}`
export const INTERVAL_TYPE_PARAM = `:intervalType(${Object.values(IntervalType)
    .filter((t) => t !== IntervalType.CUSTOM)
    .join('|')})?/`
export const CUSTOM_INTERVAL_TYPE = `:intervalType(${IntervalType.CUSTOM})`
const COMPARISON_INTERVAL_START_PARAM = `:comparisonIntervalStart${NUMBER_REGEX}`
const COMPARISON_INTERVAL_END_PARAM = `:comparisonIntervalEnd${NUMBER_REGEX}`
const WEEK_ONLY_INTERVAL_TYPE_PARAM = `:intervalType(${IntervalType.WEEK})`
const HEATMAP_DETAIL_FLOORPLAN = `:floorplanId${NUMBER_REGEX}`
const ORGANIZATION_EDIT_TAB_PARAM = `:tab(${enumToRegexp(OrganizationEditPageTabs)})`
const ORGANIZATION_EDIT_USERS_TAB_PARAM = `:tab(${OrganizationEditPageTabs.users})`
const ORGANIZATION_EDIT_LOCALITIES_TAB_PARAM = `:tab(${OrganizationEditPageTabs.localities})`
const ORGANIZATION_EDIT_SCENES_TAB_PARAM = `:tab(${OrganizationEditPageTabs.scenes})`
const ORGANIZATION_EDIT_DEVICES_TAB_PARAM = `:tab(${OrganizationEditPageTabs.devices})`
const LOCALITY_EDIT_TAB_PARAM = `:tab(${enumToRegexp(LocalityEditTabs)})/`
const LOCALITY_EDIT_SCENES_TAB_PARAM = `:tab(${LocalityEditTabs.scenes})`
const LOCALITY_EDIT_DESCRIBE_SCENE_TAB_PARAM = `:tab(${LocalityEditTabs.describeScene})`
const LOCALITY_EDIT_FLOORPLAN_TAB_PARAM = `:tab(${LocalityEditTabs.floorplan})`
const LOCALITY_EDIT_USERS_TAB_PARAM = `:tab(${LocalityEditTabs.users})`

const BOUNDARY_IDS_REGEX = `([0-9-]+)`
export const BOUNDARY_IDS_PARAM = `:boundariesIds${BOUNDARY_IDS_REGEX}?/`

const ZONE_IDS_REGEX = `([0-9-]+)`
export const ZONE_IDS_PARAM = `:zoneIds${ZONE_IDS_REGEX}?/`

const SCENE_ID_PARAM = `:sceneId([0-9]+)`
const DEVICE_ID_PARAM = `:deviceId${NUMBER_REGEX}`

export const FOOTFALL_DETAILS_EMPTY_PATH = `${VIVIDI_APP}/footfall-details/`
export const FOOTFALL_DETAILS_PATH = `${VIVIDI_APP}/footfall-details/:localityId/${INTERVAL_TYPE_PARAM}${INTERVAL_START_PARAM}?/${BOUNDARY_IDS_PARAM}`
export const FOOTFALL_DETAILS_CUSTOM_INTERVAL_PATH = `${VIVIDI_APP}/footfall-details/:localityId/:intervalType(${IntervalType.CUSTOM})/${INTERVAL_START_PARAM}/${INTERVAL_END_PARAM}/${BOUNDARY_IDS_PARAM}`

export const QUEUE_DETAILS_EMPTY_PATH = `${VIVIDI_APP}/queue/`
export const QUEUE_DETAILS_PATH = `${VIVIDI_APP}/queue/:localityId/${INTERVAL_TYPE_PARAM}${INTERVAL_START_PARAM}?/`
export const QUEUE_DETAILS_CUSTOM_INTERVAL_PATH = `${VIVIDI_APP}/queue/:localityId/:intervalType(${IntervalType.CUSTOM})/${INTERVAL_START_PARAM}/${INTERVAL_END_PARAM}`

export const HEATMAP_DETAIL_EMPTY_PATH = `${VIVIDI_APP}/heatmap-detail/`
export const HEATMAP_DETAIL_PATH = `${VIVIDI_APP}/heatmap-detail/:localityId/${WEEK_ONLY_INTERVAL_TYPE_PARAM}/${INTERVAL_START_PARAM}/${HEATMAP_DETAIL_FLOORPLAN}`
export const HEATMAP_DETAIL_PATH_NO_FP = `${VIVIDI_APP}/heatmap-detail/:localityId/${WEEK_ONLY_INTERVAL_TYPE_PARAM}/${INTERVAL_START_PARAM}/`
export const HEATMAP_DETAIL_PATH_NO_LOCALITY_NO_FP = `${VIVIDI_APP}/heatmap-detail/${INTERVAL_START_PARAM}/${INTERVAL_END_PARAM}`

export const OCCUPANCY_DETAILS_EMPTY_PATH = `${VIVIDI_APP}/occupancy-detail/`
export const OCCUPANCY_DETAILS_PATH = `${VIVIDI_APP}/occupancy-detail/:localityId?/${INTERVAL_TYPE_PARAM}${INTERVAL_START_PARAM}?/`
export const OCCUPANCY_DETAILS_CUSTOM_INTERVAL_PATH = `${VIVIDI_APP}/occupancy-detail/:localityId?/:intervalType(${IntervalType.CUSTOM})/${INTERVAL_START_PARAM}/${INTERVAL_END_PARAM}`

export const STAY_SAFE_DETAILS_EMPTY_PATH = `${VIVIDI_APP}/stay-safe-detail/`
export const STAY_SAFE_DETAILS_PATH = `${VIVIDI_APP}/stay-safe-detail/:localityId/${INTERVAL_TYPE_PARAM}${INTERVAL_START_PARAM}?/`

export const EMOTIONS_DETAILS_EMPTY_PATH = `${VIVIDI_APP}/emotions-details/`
export const EMOTIONS_DETAILS_PATH = `${VIVIDI_APP}/emotions-details/:localityId/${INTERVAL_TYPE_PARAM}${INTERVAL_START_PARAM}?/${ZONE_IDS_PARAM}`
export const EMOTIONS_DETAILS_CUSTOM_INTERVAL_PATH = `${VIVIDI_APP}/emotions-details/:localityId/:intervalType(${IntervalType.CUSTOM})/${INTERVAL_START_PARAM}/${INTERVAL_END_PARAM}/${ZONE_IDS_PARAM}`

export const DEVICE_MANAGEMENT_PATH = `${VIVIDI_APP}/devices`
export const DEVICE_TRANSFER_PATH = `${VIVIDI_APP}/devices/:id/transfer`
export const DEVICE_DIAGNOSTICS_PATH = `${VIVIDI_APP}/devices/:id/diagnostics`
export const FALSE_POSITIVE_DIAGNOSTICS_PATH = `${VIVIDI_APP}/fp-diagnostics`

export const CONVERSION_DASHBOARD_EMPTY_PATH = `${VIVIDI_APP}/conversion/`
export const CONVERSION_DASHBOARD_PATH = `${VIVIDI_APP}/conversion/:localityId/${INTERVAL_TYPE_PARAM}${INTERVAL_START_PARAM}?/`
export const CONVERSION_DASHBOARD_CUSTOM_INTERVAL_PATH = `${VIVIDI_APP}/conversion/:localityId/:intervalType(${IntervalType.CUSTOM})/${INTERVAL_START_PARAM}/${INTERVAL_END_PARAM}`

export const LOGIN_PATH = `${VIVIDI_APP}/login`
export const INSTANT_LOGIN_PATH = `${VIVIDI_APP}/instant-login`

export const MY_ORGANIZATION_PATH = `${VIVIDI_APP}/my-organization`
export const SETTINGS_PATH = `${VIVIDI_APP}/settings`
export const INVITE_USERS_PATH = `${VIVIDI_APP}/my-organization/invite-users`

// Organizations
export const ORGANIZATION_LIST_PATH = `${VIVIDI_APP}/organizations`
export const CREATE_ORGANIZATION_PATH = `${VIVIDI_APP}/organizations/create`
export const ORGANIZATION_EDIT_PATH = `${VIVIDI_APP}/organizations/:id/${ORGANIZATION_EDIT_TAB_PARAM}`

// Organization users
const USER_PARAM = ':userId([0-9]+)'
export const ORGANIZATION_USER_NEW_PATH = `${VIVIDI_APP}/organizations/:id/${ORGANIZATION_EDIT_USERS_TAB_PARAM}/new`
export const ORGANIZATION_USERS_TAB_PATH = `${VIVIDI_APP}/organizations/:id/${ORGANIZATION_EDIT_USERS_TAB_PARAM}`
export const ORGANIZATION_USER_EDIT_PATH = `${ORGANIZATION_USERS_TAB_PATH}/${USER_PARAM}`

// Organization localities
const LOCALITY_PARAM = ':localityId([0-9]+)'
const FLOORPLAN_PARAM = ':floorplanId([0-9]+)'
export const ORGANIZATION_LOCALITIES_NEW_TAB_PATH = `${VIVIDI_APP}/organizations/:id/${ORGANIZATION_EDIT_LOCALITIES_TAB_PARAM}/new`
export const ORGANIZATION_LOCALITIES_TAB_PATH = `${VIVIDI_APP}/organizations/:id/${ORGANIZATION_EDIT_LOCALITIES_TAB_PARAM}`
export const ORGANIZATION_LOCALITY_EDIT_PATH = `${ORGANIZATION_LOCALITIES_TAB_PATH}/${LOCALITY_PARAM}`
export const ORGANIZATION_LOCALITY_COPY_STATISTICS_PATH = `${ORGANIZATION_LOCALITY_EDIT_PATH}/copy-statistics`
export const ORGANIZATION_LOCALITY_DESCRIBE_SCENE_EDIT_TAB_PATH = `${ORGANIZATION_LOCALITY_EDIT_PATH}/${SCENE_ID_PARAM}`
export const ORGANIZATION_LOCALITY_EDIT_OPENING_HOURS_PATH = `${VIVIDI_APP}/organizations/:id/localities/:localityId/opening-hours`
export const ORGANIZATION_LOCALITY_EDIT_FLOORPLAN_PATH = `${VIVIDI_APP}/organizations/:id/localities/:localityId/floorplan/${FLOORPLAN_PARAM}`
export const ORGANIZATION_LOCALITY_EDIT_FLOORPLAN_MAPPING_PATH = `${VIVIDI_APP}/organizations/:id/localities/:localityId/map-to-floorplan/${FLOORPLAN_PARAM}`

// Organization scenes

export const ORGANIZATION_SCENES_NEW_TAB_PATH = `${VIVIDI_APP}/organizations/:id/${ORGANIZATION_EDIT_SCENES_TAB_PARAM}/new`
export const ORGANIZATION_SCENES_TAB_PATH = `${VIVIDI_APP}/organizations/:id/${ORGANIZATION_EDIT_SCENES_TAB_PARAM}`
export const ORGANIZATION_SCENE_EDIT_PATH = `${ORGANIZATION_SCENES_TAB_PATH}/${SCENE_ID_PARAM}`
export const ORGANIZATION_SCENE_EDIT_DESCRIBE_SCENE_MODAL_PATH = `${ORGANIZATION_SCENE_EDIT_PATH}/modal/${LOCALITY_PARAM}?/`

// Organization scenes Installer UI
export const ORGANIZATION_SCENE_INSTALLER_UI_PATH = `${ORGANIZATION_SCENES_TAB_PATH}/installer-ui`
export const ORGANIZATION_SCENE_INSTALLER_UI_ASSIGN_TO_SCENE_PATH = `${ORGANIZATION_SCENE_INSTALLER_UI_PATH}/assign/${SCENE_ID_PARAM}`
export const ORGANIZATION_SCENE_INSTALLER_UI_ASSIGNED_DEVICE_PATH = `${ORGANIZATION_SCENE_INSTALLER_UI_PATH}/device/${SCENE_ID_PARAM}/${DEVICE_ID_PARAM}`

// Organization devices
export const ORGANIZATION_DEVICES_NEW_TAB_PATH = `${VIVIDI_APP}/organizations/:id/${ORGANIZATION_EDIT_DEVICES_TAB_PARAM}/new`
export const ORGANIZATION_DEVICES_TAB_PATH = `${VIVIDI_APP}/organizations/:id/${ORGANIZATION_EDIT_DEVICES_TAB_PARAM}`
export const ORGANIZATION_DEVICE_EDIT_PATH = `${ORGANIZATION_DEVICES_TAB_PATH}/${DEVICE_ID_PARAM}`

// Localities
export const CREATE_LOCALITY_PATH = `${VIVIDI_APP}/organizations/:organizationId/locality/create`
const EDIT_LOCALITY_PATH = `${VIVIDI_APP}/organizations/:organizationId/localities/:localityId/${LOCALITY_EDIT_TAB_PARAM}`
const EDIT_LOCALITY_DESCRIBE_SCENE_TAB_PATH = `${VIVIDI_APP}/organizations/:organizationId/localities/:localityId/${LOCALITY_EDIT_DESCRIBE_SCENE_TAB_PARAM}`
const EDIT_LOCALITY_DESCRIBE_SCENE_EDIT_TAB_PATH = `${EDIT_LOCALITY_DESCRIBE_SCENE_TAB_PATH}/${SCENE_ID_PARAM}`

export const EDIT_LOCALITY_OCCUPANCY_PATH = `${VIVIDI_APP}/organizations/:organizationId/localities/:localityId/occupancy`

// Locality scenes
const LOCALITY_SCENES_TAB_PATH = `${VIVIDI_APP}/organizations/:organizationId/localities/:localityId/${LOCALITY_EDIT_SCENES_TAB_PARAM}`
const LOCALITY_SCENE_EDIT_PATH = `${LOCALITY_SCENES_TAB_PATH}/${SCENE_ID_PARAM}`

// Locality users
const LOCALITY_USERS_TAB_PATH = `${VIVIDI_APP}/organizations/:organizationId/localities/:localityId/${LOCALITY_EDIT_USERS_TAB_PARAM}`
const LOCALITY_USER_EDIT_PATH = `${LOCALITY_USERS_TAB_PATH}/${USER_PARAM}`

export const USER_EDIT_PATH = `${VIVIDI_APP}/users/:id/edit`
export const CREATE_USER_PATH = `${VIVIDI_APP}/users/create`

export const REPORT_LIST_PATH = `${VIVIDI_APP}/reports/`
export const FOOTFALL_REPORT_LIST_PATH = `${VIVIDI_APP}/reports/footfall/:localityId`
export const OCCUPANCY_REPORT_LIST_PATH = `${VIVIDI_APP}/reports/occupancy/:organizationId`
export const CONVERSION_REPORT_LIST_PATH = `${VIVIDI_APP}/reports/conversions/:localityId`
export const QUEUE_REPORT_LIST_PATH = `${VIVIDI_APP}/reports/queues/:localityId`

const STATISTICS_SUMMARY_ALL_LOCALITIES = 'all'
export const STAY_SAFE_HIDE_STORE_CAPACITY = 'hideCapacity'
const LOCALITY_IDS_REGEX = `(${STATISTICS_SUMMARY_ALL_LOCALITIES}|[0-9-]+)`
const ORGANIZATION_PARAM = `:organizationId${NUMBER_REGEX}`
const LOCALITY_IDS_PARAM = `:localityIds${LOCALITY_IDS_REGEX}`
export const STATISTICS_SUMMARY_EMPTY_PATH = `${VIVIDI_APP}`
export const STATISTICS_SUMMARY_PATH = `${VIVIDI_APP}/${LOCALITY_IDS_PARAM}/${INTERVAL_START_PARAM}/${INTERVAL_END_PARAM}/${ORGANIZATION_PARAM}?/${COMPARISON_INTERVAL_START_PARAM}?/${COMPARISON_INTERVAL_END_PARAM}?`

const generateDetailsPath = (
    basePath: string,
    idName: string,
    id: number,
    intervalStart: DateTime | null = null,
    intervalType: IntervalType | null = null,
    boundariesIds?: number[],
    zoneIds?: number[]
) => {
    let path = basePath

    path = path.replace(`${idName}/`, `${id.toString()}/`)
    path = path.replace(`${INTERVAL_START_PARAM}?/`, intervalStart ? `${intervalStart.valueOf().toString()}/` : '')
    path = path.replace(INTERVAL_TYPE_PARAM, intervalType ? `${intervalType}/` : '')

    if (boundariesIds && !isEmpty(boundariesIds)) {
        path = path.replace(BOUNDARY_IDS_PARAM, boundariesIds.join('-'))
    } else {
        path = path.replace(BOUNDARY_IDS_PARAM, '')
    }

    if (zoneIds && !isEmpty(zoneIds)) {
        path = path.replace(ZONE_IDS_PARAM, zoneIds.join('-'))
    } else {
        path = path.replace(ZONE_IDS_PARAM, '')
    }

    return path
}

export const generateStaySafeDetailsPath = (
    localityId: number,
    intervalStart: DateTime | null = null,
    _: IntervalType | null = null
) => {
    return generateDetailsPath(STAY_SAFE_DETAILS_PATH, ':localityId', localityId, intervalStart, IntervalType.DAY)
}
export const generateEmotionsDetailsPath = (
    localityId: number,
    intervalStart: DateTime | null = null,
    intervalType: IntervalType | null = null,
    zoneIds?: number[]
) => {
    return generateDetailsPath(EMOTIONS_DETAILS_PATH, ':localityId', localityId, intervalStart, intervalType, zoneIds)
}

export const generateFootfallDetailsPath = (
    localityId: number,
    intervalStart: DateTime | null = null,
    intervalType: IntervalType | null = null,
    boundariesIds?: number[]
) => generateDetailsPath(FOOTFALL_DETAILS_PATH, ':localityId', localityId, intervalStart, intervalType, boundariesIds)

export const generateHeatmapDetailPath = (
    localityId: number,
    intervalStart: DateTime | null = null,
    _intervalType: IntervalType | null = null,
    floorplanId: number | null = null
) => {
    let path = HEATMAP_DETAIL_PATH

    path = path.replace(':localityId', localityId.toString())
    path = path.replace(`${INTERVAL_START_PARAM}/`, intervalStart ? `${intervalStart.valueOf().toString()}/` : '')
    path = path.replace(WEEK_ONLY_INTERVAL_TYPE_PARAM, IntervalType.WEEK)
    path = path.replace(HEATMAP_DETAIL_FLOORPLAN, floorplanId ? floorplanId.toString() : '')

    return path
}

export const generateNoLocalityNoFPHeatmapPath = (intervalStart: DateTime, intervalEnd: DateTime) => {
    let path = HEATMAP_DETAIL_PATH_NO_LOCALITY_NO_FP

    path = path.replace(INTERVAL_START_PARAM, intervalStart.valueOf().toString())
    path = path.replace(INTERVAL_END_PARAM, intervalEnd.valueOf().toString())

    return path
}

export const generateFootfallDetailsCustomIntervalPath = (
    id: number,
    intervalStart: DateTime,
    intervalEnd: DateTime,
    boundariesIds?: number[]
) => {
    let path = FOOTFALL_DETAILS_CUSTOM_INTERVAL_PATH

    path = path.replace(':localityId', id.toString())
    path = path.replace(INTERVAL_START_PARAM, intervalStart.valueOf().toString())
    path = path.replace(INTERVAL_END_PARAM, intervalEnd.valueOf().toString())
    path = path.replace(`:intervalType(${IntervalType.CUSTOM})`, IntervalType.CUSTOM)

    if (boundariesIds && !isEmpty(boundariesIds)) {
        path = path.replace(BOUNDARY_IDS_PARAM, boundariesIds.join('-'))
    } else {
        path = path.replace(BOUNDARY_IDS_PARAM, '')
    }

    return path
}

export const generateEmotionsDetailsCustomIntervalPath = (
    id: number,
    intervalStart: DateTime,
    intervalEnd: DateTime,
    zoneIds?: number[]
) => {
    let path = EMOTIONS_DETAILS_CUSTOM_INTERVAL_PATH

    path = path.replace(':localityId', id.toString())
    path = path.replace(INTERVAL_START_PARAM, intervalStart.valueOf().toString())
    path = path.replace(INTERVAL_END_PARAM, intervalEnd.valueOf().toString())
    path = path.replace(`:intervalType(${IntervalType.CUSTOM})`, IntervalType.CUSTOM)

    if (zoneIds && !isEmpty(zoneIds)) {
        path = path.replace(ZONE_IDS_PARAM, zoneIds.join('-'))
    } else {
        path = path.replace(ZONE_IDS_PARAM, '')
    }

    return path
}

export const generateQueueDetailsPath = (
    localityId: number,
    intervalStart: DateTime | null = null,
    intervalType: IntervalType | null = null
) => generateDetailsPath(QUEUE_DETAILS_PATH, ':localityId', localityId, intervalStart, intervalType)

export const generateQueueDetailsCustomIntervalPath = (id: number, intervalStart: DateTime, intervalEnd: DateTime) => {
    let path = QUEUE_DETAILS_CUSTOM_INTERVAL_PATH

    path = path.replace(':localityId', id.toString())
    path = path.replace(INTERVAL_START_PARAM, intervalStart.valueOf().toString())
    path = path.replace(INTERVAL_END_PARAM, intervalEnd.valueOf().toString())
    path = path.replace(`:intervalType(${IntervalType.CUSTOM})`, IntervalType.CUSTOM)

    return path
}

export const generateConversionDashboardPath = (
    localityId: number,
    intervalStart: DateTime | null = null,
    intervalType: IntervalType | null = null
) => {
    let path = CONVERSION_DASHBOARD_PATH
    path = path.replace(':localityId', localityId.toString())
    path = path.replace(`${INTERVAL_START_PARAM}?/`, intervalStart ? `${intervalStart.valueOf().toString()}/` : '')
    path = path.replace(INTERVAL_TYPE_PARAM, intervalType ? `${intervalType}/` : '')

    return path
}

export const generateConversionDashboardCustomIntervalPath = (
    localityId: number,
    intervalStart: DateTime,
    intervalEnd: DateTime
) => {
    let path = CONVERSION_DASHBOARD_CUSTOM_INTERVAL_PATH

    path = path.replace(':localityId', localityId.toString())
    path = path.replace(INTERVAL_START_PARAM, intervalStart.valueOf().toString())
    path = path.replace(INTERVAL_END_PARAM, intervalEnd.valueOf().toString())
    path = path.replace(`:intervalType(${IntervalType.CUSTOM})`, IntervalType.CUSTOM)

    return path
}

export const generateOccupancyDetailsPath = (
    localityId: number | null = null,
    intervalStart: DateTime | null = null,
    intervalType: IntervalType | null = null
) => {
    let path = OCCUPANCY_DETAILS_PATH

    path = path.replace(':localityId?/', localityId !== null ? `${localityId.toString()}/` : '')
    path = path.replace(`${INTERVAL_START_PARAM}?/`, intervalStart ? `${intervalStart.valueOf().toString()}/` : '')
    path = path.replace(INTERVAL_TYPE_PARAM, intervalType ? `${intervalType}/` : '')

    return path
}

export const generateOccupancyDetailsCustomPath = (
    localityId: number,
    intervalStart: DateTime,
    intervalEnd: DateTime
) => {
    let path = OCCUPANCY_DETAILS_CUSTOM_INTERVAL_PATH

    path = path.replace(':localityId?/', `${localityId.toString()}/`)
    path = path.replace(INTERVAL_START_PARAM, intervalStart.valueOf().toString())
    path = path.replace(INTERVAL_END_PARAM, intervalEnd.valueOf().toString())
    path = path.replace(`:intervalType(${IntervalType.CUSTOM})`, IntervalType.CUSTOM)

    return path
}

export const generateStatisticsSummaryPath = (
    isAll: boolean,
    localityIds: number[],
    interval: Interval,
    comparisonInterval?: Interval,
    organizationId?: number
) => {
    let path = STATISTICS_SUMMARY_PATH

    if (!isAll && localityIds.length !== 0) {
        path = path.replace(LOCALITY_IDS_PARAM, localityIds.join('-'))
    } else {
        path = path.replace(LOCALITY_IDS_PARAM, STATISTICS_SUMMARY_ALL_LOCALITIES)
    }

    path = path.replace(`${ORGANIZATION_PARAM}?`, organizationId ? organizationId.toString() : '')
    path = path.replace(INTERVAL_START_PARAM, interval.start.valueOf().toString())
    path = path.replace(INTERVAL_END_PARAM, interval.end.valueOf().toString())
    path = path.replace(
        `/${COMPARISON_INTERVAL_START_PARAM}?`,
        comparisonInterval ? `/${comparisonInterval.start.valueOf().toString()}` : ''
    )
    path = path.replace(
        `/${COMPARISON_INTERVAL_END_PARAM}?`,
        comparisonInterval ? `/${comparisonInterval.end.valueOf().toString()}` : ''
    )

    return path
}

export const generateSceneDiagnosticsPath = (id: number) => DEVICE_DIAGNOSTICS_PATH.replace(':id', id.toString())

export const generateDeviceTransferPath = (id: number) => DEVICE_TRANSFER_PATH.replace(':id', id.toString())

export const generateEditOrganizationPath = (id: number, tab: string) =>
    ORGANIZATION_EDIT_PATH.replace(':id', id.toString()).replace(ORGANIZATION_EDIT_TAB_PARAM, tab)

export const generateEditLocalityPath = (organizationId: number, localityId: number, tab: string, sceneId?: number) => {
    if (sceneId) {
        return EDIT_LOCALITY_DESCRIBE_SCENE_EDIT_TAB_PATH.replace(':organizationId', organizationId.toString())
            .replace(':localityId', localityId.toString())
            .replace(LOCALITY_EDIT_DESCRIBE_SCENE_TAB_PARAM, LocalityEditTabs.describeScene)
            .replace(SCENE_ID_PARAM, sceneId.toString())
    }

    return EDIT_LOCALITY_PATH.replace(':organizationId', organizationId.toString())
        .replace(':localityId', localityId.toString())
        .replace(LOCALITY_EDIT_TAB_PARAM, tab)
}

export const generateEditLocalityOccupancyPath = (organizationId: number, localityId: number) =>
    EDIT_LOCALITY_OCCUPANCY_PATH.replace(':organizationId', organizationId.toString()).replace(
        ':localityId',
        localityId.toString()
    )

export const generateOrganizationsEditLocalitiesPath = (
    organizationId: number,
    localityId: number,
    sceneId?: number
) => {
    if (sceneId) {
        return ORGANIZATION_LOCALITY_DESCRIBE_SCENE_EDIT_TAB_PATH.replace(':id', organizationId.toString())
            .replace(ORGANIZATION_EDIT_LOCALITIES_TAB_PARAM, OrganizationEditPageTabs.localities)
            .replace(LOCALITY_PARAM, localityId.toString())
            .replace(SCENE_ID_PARAM, sceneId.toString())
    }

    return ORGANIZATION_LOCALITY_EDIT_PATH.replace(':id', organizationId.toString())
        .replace(ORGANIZATION_EDIT_LOCALITIES_TAB_PARAM, OrganizationEditPageTabs.localities)
        .replace(LOCALITY_PARAM, localityId.toString())
}

export const generateOrganizationsEditLocalitiesCopyStatisticsPath = (organizationId: number, localityId: number) => {
    return ORGANIZATION_LOCALITY_COPY_STATISTICS_PATH.replace(':id', organizationId.toString())
        .replace(ORGANIZATION_EDIT_LOCALITIES_TAB_PARAM, OrganizationEditPageTabs.localities)
        .replace(LOCALITY_PARAM, localityId.toString())
}

export const generateOrganizationsEditLocalitiesOpeningHoursPath = (organizationId: number, localityId: number) =>
    ORGANIZATION_LOCALITY_EDIT_OPENING_HOURS_PATH.replace(':id', organizationId.toString()).replace(
        ':localityId',
        localityId.toString()
    )

export const generateOrganizationsCreateLocalitiesPath = (organizationId: number) =>
    ORGANIZATION_LOCALITIES_NEW_TAB_PATH.replace(':id', organizationId.toString()).replace(
        ORGANIZATION_EDIT_LOCALITIES_TAB_PARAM,
        OrganizationEditPageTabs.localities
    )

export const generateOrganizationEditSceneInstallerUIPath = (organizationId: number) =>
    ORGANIZATION_SCENE_INSTALLER_UI_PATH.replace(':id', organizationId.toString()).replace(
        ORGANIZATION_EDIT_SCENES_TAB_PARAM,
        OrganizationEditPageTabs.scenes
    )

export const generateOrganizationEditSceneInstallerUIAssignToScenePath = (organizationId: number, sceneId: number) =>
    ORGANIZATION_SCENE_INSTALLER_UI_ASSIGN_TO_SCENE_PATH.replace(':id', organizationId.toString())
        .replace(ORGANIZATION_EDIT_SCENES_TAB_PARAM, OrganizationEditPageTabs.scenes)
        .replace(SCENE_ID_PARAM, sceneId.toString())

export const generateOrganizationEditSceneInstallerUIAssignDevicePath = (
    organizationId: number,
    sceneId: number,
    deviceId: number
) =>
    ORGANIZATION_SCENE_INSTALLER_UI_ASSIGNED_DEVICE_PATH.replace(':id', organizationId.toString())
        .replace(ORGANIZATION_EDIT_SCENES_TAB_PARAM, LocalityEditTabs.scenes)
        .replace(SCENE_ID_PARAM, sceneId.toString())
        .replace(DEVICE_ID_PARAM, deviceId.toString())

export const generateOrganizationEditUserPath = (id: number, organizationId?: number) => {
    if (organizationId === undefined) {
        return USER_EDIT_PATH.replace(':id', id.toString())
    }

    return ORGANIZATION_USER_EDIT_PATH.replace(':id', organizationId.toString())
        .replace(ORGANIZATION_EDIT_USERS_TAB_PARAM, OrganizationEditPageTabs.users)
        .replace(USER_PARAM, id.toString())
}
export const generateOrganizationCreateUserPath = (organizationId: number) =>
    ORGANIZATION_USER_NEW_PATH.replace(':id', organizationId.toString()).replace(
        ORGANIZATION_EDIT_USERS_TAB_PARAM,
        OrganizationEditPageTabs.users
    )

export const generateOrganizationEditScenePath = (organizationId: number) =>
    ORGANIZATION_SCENES_TAB_PATH.replace(':id', organizationId.toString()).replace(
        ORGANIZATION_EDIT_SCENES_TAB_PARAM,
        OrganizationEditPageTabs.scenes
    )

export const generateOrganizationScenesPath = (organizationId: number, sceneId: number) =>
    ORGANIZATION_SCENE_EDIT_PATH.replace(':id', organizationId.toString())
        .replace(ORGANIZATION_EDIT_SCENES_TAB_PARAM, OrganizationEditPageTabs.scenes)
        .replace(SCENE_ID_PARAM, sceneId.toString())

export const generateOrganizationCreateScenePath = (organizationId: number) =>
    ORGANIZATION_SCENES_NEW_TAB_PATH.replace(':id', organizationId.toString()).replace(
        ORGANIZATION_EDIT_SCENES_TAB_PARAM,
        OrganizationEditPageTabs.scenes
    )

export const generateOrganizationEditSceneDescribePath = (
    organizationId: number,
    sceneId: number,
    localityId?: number
) => {
    if (localityId) {
        return ORGANIZATION_SCENE_EDIT_DESCRIBE_SCENE_MODAL_PATH.replace(':id', organizationId.toString())
            .replace(ORGANIZATION_EDIT_SCENES_TAB_PARAM, OrganizationEditPageTabs.scenes)
            .replace(SCENE_ID_PARAM, sceneId.toString())
            .replace(LOCALITY_EDIT_DESCRIBE_SCENE_TAB_PARAM, LocalityEditTabs.describeScene)
            .replace(`${LOCALITY_PARAM}?/`, localityId.toString())
    }

    return ORGANIZATION_SCENE_EDIT_DESCRIBE_SCENE_MODAL_PATH.replace(':id', organizationId.toString())
        .replace(ORGANIZATION_EDIT_SCENES_TAB_PARAM, OrganizationEditPageTabs.scenes)
        .replace(SCENE_ID_PARAM, sceneId.toString())
        .replace(LOCALITY_EDIT_DESCRIBE_SCENE_TAB_PARAM, LocalityEditTabs.describeScene)
        .replace(`${LOCALITY_PARAM}?/`, '')
}

export const generateLocalityEditScenePath = (organizationId: number, localityId: number, sceneId: number) => {
    return LOCALITY_SCENE_EDIT_PATH.replace(':organizationId', organizationId.toString())
        .replace(':localityId', localityId.toString())
        .replace(LOCALITY_EDIT_SCENES_TAB_PARAM, LocalityEditTabs.scenes)
        .replace(SCENE_ID_PARAM, sceneId.toString())
}

export const generateOrganizationEditLocalityFloorplanPath = (
    organizationId: number,
    localityId: number,
    floorplan: number
) => {
    return ORGANIZATION_LOCALITY_EDIT_FLOORPLAN_PATH.replace(':id', organizationId.toString())
        .replace(':localityId', localityId.toString())
        .replace(LOCALITY_EDIT_FLOORPLAN_TAB_PARAM, LocalityEditTabs.floorplan)
        .replace(FLOORPLAN_PARAM, floorplan.toString())
}

export const generateOrganizationEditDevicesPath = (organizationId: number) =>
    ORGANIZATION_DEVICES_TAB_PATH.replace(':id', organizationId.toString()).replace(
        ORGANIZATION_EDIT_DEVICES_TAB_PARAM,
        OrganizationEditPageTabs.devices
    )

export const generateOrganizationEditDevicePath = (organizationId: number, deviceId: number) => {
    return ORGANIZATION_DEVICE_EDIT_PATH.replace(':id', organizationId.toString())
        .replace(ORGANIZATION_EDIT_DEVICES_TAB_PARAM, OrganizationEditPageTabs.devices)
        .replace(DEVICE_ID_PARAM, deviceId.toString())
}

export const generateOrganizationEditDeviceCreatePath = (organizationId: number) =>
    ORGANIZATION_DEVICES_NEW_TAB_PATH.replace(':id', organizationId.toString()).replace(
        ORGANIZATION_EDIT_DEVICES_TAB_PARAM,
        OrganizationEditPageTabs.devices
    )

export const generateOrganizationEditLocalityFloorplanMappingPath = (
    organizationId: number,
    localityId: number,
    floorplan: number
) => {
    return ORGANIZATION_LOCALITY_EDIT_FLOORPLAN_MAPPING_PATH.replace(':id', organizationId.toString())
        .replace(':localityId', localityId.toString())
        .replace(FLOORPLAN_PARAM, floorplan.toString())
}

export const generateLocalityEditUserPath = (organizationId: number, localityId: number, userId: number) =>
    LOCALITY_USER_EDIT_PATH.replace(':organizationId', organizationId.toString())
        .replace(':localityId', localityId.toString())
        .replace(LOCALITY_EDIT_USERS_TAB_PARAM, LocalityEditTabs.users)
        .replace(USER_PARAM, userId.toString())

export const generateLocalityOccupancyDashboardPath = (id: number, lang: string, hideCapacity?: string) =>
    LOCALITY_OCCUPANCY_DASHBOARD_PATH.replace(':id', id.toString())
        .replace(':lang?', lang)
        .replace(':hideCapacity?', hideCapacity ?? '')

export const generateFloorplanLocalityOccupancyDashboardPath = (id: number, lang: string) =>
    FLOORPLAN_LOCALITY_OCCUPANCY_DASHBOARD_PATH.replace(':id', id.toString()).replace(':lang?', lang)

export const generateFootfallReportListPath = (localityId: number) =>
    FOOTFALL_REPORT_LIST_PATH.replace(':localityId', localityId.toString())

export const generateOccupancyReportListPath = (organizationId: number) =>
    OCCUPANCY_REPORT_LIST_PATH.replace(':organizationId', organizationId.toString())

export const generateConversionReportListPath = (localityId: number) =>
    CONVERSION_REPORT_LIST_PATH.replace(':localityId', localityId.toString())

export const generateQueueReportListPath = (localityId: number) =>
    QUEUE_REPORT_LIST_PATH.replace(':localityId', localityId.toString())
