import { LocationDescriptorObject } from 'history'
import { QueryClient } from 'react-query'
import { matchPath } from 'react-router-dom'

import { isApiError } from '@helpers/apiCallManipulation'

import { Configuration, HTTPHeaders, Middleware } from './api'
import {
    AuthenticationAndTokenManagementApi,
    BackgroundJobsApi,
    DeviceManagementApi,
    LocalitiesApi,
    LocalityScenesApi,
    ReportsApi,
    UserProfileApi,
    UserManagementApi,
    VividiImageManagementApi,
    OrganizationsApi,
    WeatherApi,
} from './api/apis'
import { LOGIN_PATH } from './helpers/VividiURLs'
import { StatisticApi } from './helpers/api'
import { AUTH_KEY, AuthContext } from './helpers/auth'
import vividiRoutes from './routes/vividiRoutes'

export const googleMapsUrl = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAPS_API_KEY}&libraries=places`

const authMiddleware: Middleware = {
    pre: async (context) => {
        const headers: HTTPHeaders | undefined = context.init.headers as HTTPHeaders

        const authContextString = window.localStorage.getItem(AUTH_KEY)

        if (authContextString !== null) {
            const authContext = JSON.parse(authContextString) as AuthContext
            headers['Authorization'] = `Bearer ${authContext.accessToken}`
        }

        return context
    },
    post: async (context) => {
        if (context.response.status === 401) {
            // The login expired -> remove auth data and proceed to redirection
            window.localStorage.removeItem(AUTH_KEY)

            const currentPath = window.location.pathname
            const matchingRoute = vividiRoutes.find((route) => matchPath(currentPath, route))

            // If current path requires authentication, redirect to login page
            if (!matchingRoute?.isNotSecured && navigator.userAgent !== 'ReactSnap') {
                window.history.pushState(
                    {
                        backLink: {
                            pathname: window.location.pathname,
                            search: window.location.search,
                            hash: window.location.hash,
                        } as LocationDescriptorObject,
                    },
                    '',
                    LOGIN_PATH
                )
            }
        }

        return context.response
    },
}

const configuration = new Configuration({
    basePath: process.env.REACT_APP_API_URL!,
    middleware: [authMiddleware],
})

export const authApi = new AuthenticationAndTokenManagementApi(configuration)

export const statisticsApi = new StatisticApi(configuration)

export const profileApi = new UserProfileApi(configuration)

export const organizationApi = new OrganizationsApi(configuration)

export const localityApi = new LocalitiesApi(configuration)

export const deviceApi = new DeviceManagementApi(configuration)

export const sceneApi = new LocalityScenesApi(configuration)

export const userApi = new UserManagementApi(configuration)

export const vividiImagesApi = new VividiImageManagementApi(configuration)

export const reportsApi = new ReportsApi(configuration)

export const weatherApi = new WeatherApi(configuration)

export const backgroundJobsApi = new BackgroundJobsApi(configuration)

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            suspense: false,
            useErrorBoundary: false,
            retry: (failureCount, error) => failureCount <= 3 && (!isApiError(error) || error.status >= 500),
        },
    },
})
