import { noop } from 'lodash'
import { DateTime, Duration, Interval } from 'luxon'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'

import { LocalityNameModel } from '@api'

import { localityApi, statisticsApi } from '@services'

import { generateQueueDetailsCustomIntervalPath, generateQueueDetailsPath } from '@helpers/VividiURLs'
import { mergeQueryResults, visibleSceneDescriptionsQuery } from '@helpers/api'
import { useTimezoneConfig } from '@helpers/timezoneConfig'
import { IntervalType } from '@helpers/types'
import { pickColumn } from '@helpers/utils'

import DashboardParamsNormalizer, { NormalizedDashboardParams } from '@components/DashboardParamsNormalizer'
import DetailsLayout from '@components/DetailsLayout'
import ErrorView from '@components/ErrorView'
import Helmet from '@components/Helmet'
import LegacyLoadingWrapper from '@components/LegacyLoadingWrapper'
import LoadingSpinner from '@components/LoadingSpinner'
import QueueDetailsContent from '@components/QueueDetails/QueueDetailsContent'
import { QueueDetailsExportButton } from '@components/QueueDetails/QueueDetailsExportButton'
import { partitionInterval } from '@components/plots/common'

const QueueDetailsPage: React.FC<NormalizedDashboardParams> = ({ locality, interval, intervalType }) => {
    const { t } = useTranslation()

    const zone = useTimezoneConfig()
    const history = useHistory()
    const [now] = useState(DateTime.utc())

    const isPhilipMorris = locality.organizationId === 127 && process.env.REACT_APP_API_URL!.endsWith('api.vividi.io')

    const partitionedInterval = partitionInterval(
        interval,
        zone,
        Duration.fromObject(
            interval.end.diff(interval.start) > Duration.fromObject({ days: 7 }) && !isPhilipMorris
                ? { days: 1 }
                : { hours: 1 }
        )
    )

    const scenesCall = useQuery(
        localityApi.getLocalityScenes.query({
            localityId: locality.id,
        })
    )

    const sceneConfigurationsCall = useQuery({
        ...visibleSceneDescriptionsQuery(pickColumn(scenesCall.data?.scenes ?? [], 'id')),
        enabled: scenesCall.isSuccess,
    })

    const queueDataCall = useQuery(
        statisticsApi.getQueueStatistics.query({
            body: {
                locality: locality.id,
                ...partitionedInterval,
            },
        })
    )

    const salesDetailsCall = useQuery(
        statisticsApi.getSalesStatisticsDetails.query({
            body: {
                locality: locality.id,
                ...partitionedInterval,
            },
        })
    )

    const handleIntervalStartChange = (intervalStart: DateTime) => {
        history.push(generateQueueDetailsPath(locality.id, intervalStart, intervalType))
    }

    const handleIntervalTypeChange = (newIntervalType: IntervalType) => {
        history.push(generateQueueDetailsPath(locality.id, interval.start, newIntervalType))
    }

    const handleLocalityChange = (locality: LocalityNameModel) => {
        const isCustomIntervalSelected = intervalType === IntervalType.CUSTOM && interval.start && interval.end

        if (isCustomIntervalSelected) {
            return history.push(generateQueueDetailsCustomIntervalPath(locality.id, interval.start, interval.end))
        }

        return history.push(generateQueueDetailsPath(locality.id, interval.start, intervalType))
    }

    const handleCustomIntervalChange = (interval: Interval) => {
        history.push(generateQueueDetailsCustomIntervalPath(locality.id, interval.start, interval.end))
    }

    const documentTitle = t('title.queuesStatistics', 'Queues Statistics')

    return (
        <>
            <Helmet>
                <title>{documentTitle}</title>
            </Helmet>
            <LegacyLoadingWrapper
                errorComponent={
                    <DetailsLayout
                        intervalType={intervalType}
                        organizationFilter={(org) => org.features.queueMonitoring}
                        pickedInterval={interval}
                        selectedLocality={locality}
                        onCustomIntervalChange={handleCustomIntervalChange}
                        onIntervalStartChange={handleIntervalStartChange}
                        onIntervalTypeChange={handleIntervalTypeChange}
                        onLocalityChange={handleLocalityChange}
                    >
                        <ErrorView message={t('others.problemFetchingData', 'There was a problem fetching data.')} />
                    </DetailsLayout>
                }
                placeholder={
                    <DetailsLayout
                        intervalType={intervalType}
                        organizationFilter={(org) => org.features.queueMonitoring}
                        pickedInterval={interval}
                        selectedLocality={locality}
                        onCustomIntervalChange={handleCustomIntervalChange}
                        onIntervalStartChange={handleIntervalStartChange}
                        onIntervalTypeChange={handleIntervalTypeChange}
                        onLocalityChange={handleLocalityChange}
                    >
                        <LoadingSpinner />
                    </DetailsLayout>
                }
                request={mergeQueryResults(queueDataCall, salesDetailsCall, scenesCall, sceneConfigurationsCall)}
            >
                {([queueData, salesDetails, scenes, sceneConfigurations]) => (
                    <>
                        <Helmet>
                            <title>{`${locality.name} ${documentTitle}`}</title>
                        </Helmet>
                        <DetailsLayout
                            key={locality.id}
                            intervalType={intervalType}
                            layoutActionButton={
                                <QueueDetailsExportButton
                                    pickedInterval={interval}
                                    queueData={queueData}
                                    salesDetails={salesDetails}
                                    selectedLocality={locality}
                                />
                            }
                            organizationFilter={(org) => org.features.queueMonitoring}
                            pickedInterval={interval}
                            selectedLocality={locality}
                            onCustomIntervalChange={handleCustomIntervalChange}
                            onIntervalStartChange={handleIntervalStartChange}
                            onIntervalTypeChange={handleIntervalTypeChange}
                            onLocalityChange={handleLocalityChange}
                        >
                            <QueueDetailsContent
                                interval={interval}
                                intervalType={intervalType}
                                locality={locality}
                                now={now}
                                queueData={queueData}
                                salesDetails={salesDetails}
                                sceneConfiguration={sceneConfigurations}
                                scenes={scenes}
                            />
                        </DetailsLayout>
                    </>
                )}
            </LegacyLoadingWrapper>
        </>
    )
}

const QueueDetailsParamsNormalizer: React.FC = () => {
    const { t } = useTranslation()

    return (
        <DashboardParamsNormalizer
            customIntervalPathGenerator={generateQueueDetailsCustomIntervalPath}
            defaultLocalityPathGenerator={generateQueueDetailsPath}
            errorComponent={({ intervalType, interval }) => (
                <DetailsLayout
                    intervalType={intervalType}
                    organizationFilter={(org) => org.features.queueMonitoring}
                    pickedInterval={interval}
                    onCustomIntervalChange={noop}
                    onIntervalStartChange={noop}
                    onIntervalTypeChange={noop}
                    onLocalityChange={noop}
                >
                    <ErrorView
                        message={t('others.fetchingListFailed', 'Fetching the list of localities failed')}
                        title={t('others.errorWhileFetchingData', 'Error while fetching data')}
                    />
                </DetailsLayout>
            )}
            intervalPathGenerator={generateQueueDetailsPath}
            loadingComponent={({ intervalType, interval }) => (
                <DetailsLayout
                    intervalType={intervalType}
                    organizationFilter={(org) => org.features.queueMonitoring}
                    pickedInterval={interval}
                    onCustomIntervalChange={noop}
                    onIntervalStartChange={noop}
                    onIntervalTypeChange={noop}
                    onLocalityChange={noop}
                >
                    <LoadingSpinner />
                </DetailsLayout>
            )}
            successComponent={(params) => <QueueDetailsPage {...params} />}
        />
    )
}

export default QueueDetailsParamsNormalizer
