import { concat, Dictionary, fromPairs, isEmpty } from 'lodash'
import { useCallback } from 'react'
import {
    notifyManager,
    QueryKey,
    QueryObserver,
    QueryObserverResult,
    QueryObserverSuccessResult,
    useQueries,
    useQuery,
    useQueryClient,
    useQueryErrorResetBoundary,
    UseQueryOptions,
} from 'react-query'

import {
    DatetimeInterval,
    EmotionSummaryResponse,
    LocalityFloorplanMappingList,
    LocalityNameListResponse,
    LocalityNameModel,
    LocalityResponse,
    OrganizationListResponse,
    ProfileResponse,
    QueueStatisticsResponse,
    Role,
    SceneDescription,
    UserListResponse,
    ZoneOccupancySessionsResponse,
    ZoneOccupancySummaryResponse,
} from '@api'

import { LocalitiesApi, LocalityScenesApi, OrganizationsApi, StatisticsApi, UserManagementApi } from '@api/apis'

import { localityApi, organizationApi, sceneApi, statisticsApi, userApi } from '@services'

export const useApiCallCleaner = () => {
    const client = useQueryClient()

    return useCallback(
        (api?: { name: string }) => {
            if (api === undefined) {
                client.clear()
            } else {
                client.resetQueries([api.name])
            }
        },
        [client]
    )
}

export function mergeQueryResults<TupleType extends Array<any>>(
    ...args: { [P in keyof TupleType]: QueryObserverResult<TupleType[P]> }
): QueryObserverResult<TupleType> {
    const idleResult = args.find((req) => req.status === 'idle')

    if (idleResult) {
        return idleResult
    }

    const loadingResult = args.find((req) => req.status === 'loading')

    if (loadingResult) {
        return loadingResult
    }

    const failed = args.find((req) => req.status === 'error')

    if (failed) {
        return failed
    }

    return {
        ...args[0],
        isIdle: false,
        isSuccess: true,
        isLoading: false,
        isError: false,
        isLoadingError: false,
        isRefetchError: false,
        error: null,
        status: 'success',
        data: args.map((req) => req.data!) as TupleType,
    }
}

export const useSuspendingQuery = <
    TQueryFnData = unknown,
    TError = unknown,
    TData = TQueryFnData,
    TQueryKey extends QueryKey = QueryKey
>(
    options: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'suspense' | 'useErrorBoundary' | 'enabled'>
): QueryObserverSuccessResult<TData, TError> =>
    useQuery({ ...options, enabled: true, suspense: true, useErrorBoundary: true }) as QueryObserverSuccessResult<
        TData,
        TError
    >

export const useSuspendingQueries = <T extends any[]>(
    ...queries: {
        [Index in keyof T]: Omit<UseQueryOptions<T[Index]>, 'suspense' | 'useErrorBoundary' | 'enabled'>
    }
): {
    [Index in keyof T]: QueryObserverSuccessResult<T[Index]>
} => {
    const client = useQueryClient()
    const errorResetBoundary = useQueryErrorResetBoundary()

    const defaultedQueries: UseQueryOptions[] = queries.map((query) => {
        const defaulted = client.defaultQueryObserverOptions(query)

        if (defaulted.onError) {
            defaulted.onError = notifyManager.batchCalls(defaulted.onError)
        }

        if (defaulted.onSuccess) {
            defaulted.onSuccess = notifyManager.batchCalls(defaulted.onSuccess)
        }

        if (defaulted.onSettled) {
            defaulted.onSettled = notifyManager.batchCalls(defaulted.onSettled)
        }

        if (typeof defaulted.staleTime !== 'number') {
            defaulted.staleTime = 1000
        }

        if (!errorResetBoundary.isReset()) {
            defaulted.retryOnMount = false
        }

        defaulted.optimisticResults = true
        defaulted.useErrorBoundary = false
        defaulted.enabled = true

        return defaulted
    })

    const result = useQueries(defaultedQueries)

    const failedResult = result.find((q) => q.status === 'error')

    if (failedResult !== undefined) {
        throw failedResult.error
    }

    const loadingIndex = result.findIndex((q) => q.dataUpdatedAt === 0)

    if (loadingIndex === -1) {
        return result as any
    }

    const observer = new QueryObserver(client, queries[loadingIndex])
    const unsubscribe = observer.subscribe()

    throw observer.refetch().finally(unsubscribe)
}

export const visibleSceneDescriptionsQuery = (sceneIds?: number[]) => ({
    queryKey: [LocalityScenesApi.name, 'getSceneDescriptions', sceneIds],
    queryFn: async (): Promise<Dictionary<SceneDescription>> => {
        return fromPairs(
            await Promise.all(
                (sceneIds ?? []).map(async (sceneId) => [sceneId, await sceneApi.getSceneDescription({ sceneId })])
            )
        )
    },
    enabled: sceneIds !== undefined,
})

export const visibleLocalitiesInfo = (localities: LocalityNameModel[]) => ({
    queryKey: [LocalitiesApi.name, 'getLocalitiesInfo', localities],
    queryFn: async (): Promise<LocalityResponse[]> => {
        return await Promise.all(
            localities.map(async (locality) => {
                const localityInfo = await localityApi.getLocality({
                    localityId: locality.id,
                })

                return localityInfo
            })
        )
    },
})

export const visibleLocalitiesQuery = (profile?: ProfileResponse): UseQueryOptions<LocalityNameListResponse> => ({
    queryKey: [LocalitiesApi.name, 'getVisibleLocalities', profile?.id],
    enabled: profile !== undefined,
    queryFn: async () => {
        if (profile!.role === Role.User) {
            return {
                localities: profile!.localities,
            }
        }

        return await localityApi.getLocalityNames()
    },
})

export const visibleFloorplanMappingsQuery = (
    locality?: LocalityResponse
): UseQueryOptions<LocalityFloorplanMappingList> => ({
    enabled: locality !== undefined,
    queryKey: [LocalitiesApi.name, 'getAllFloorplanMappings', locality?.id],
    queryFn: async () => {
        if (isEmpty(locality!.floorplans)) {
            return { mappings: [] }
        }

        const results = await Promise.all(
            Object.keys(locality!.floorplans).map(async (floorplanId) => {
                const result = await localityApi.getLocalityFloorplanMappings({
                    organizationId: locality!.organizationId,
                    localityId: locality!.id,
                    floorplanId: Number(floorplanId),
                })

                return result.mappings
            })
        )

        return {
            mappings: concat(results[0], ...results.slice(1)),
        }
    },
})

export const visibleOrganizationsQuery = (profile?: ProfileResponse): UseQueryOptions<OrganizationListResponse> => ({
    queryKey: [OrganizationsApi.name, 'getVisibleOrganizations', profile?.id],
    enabled: profile !== undefined,
    queryFn: async (): Promise<OrganizationListResponse> => {
        if (profile!.role === Role.Administrator) {
            return await organizationApi.getOrganizations()
        }

        const organization = await organizationApi.getOrganization({
            organizationId: profile!.organizationId!,
        })

        return {
            organizations: [organization],
            owners: {},
        }
    },
})

export const emotionsSummaryByLocalityQuery = ({
    localityIds,
    intervals,
}: {
    localityIds: number[]
    intervals: DatetimeInterval[]
}): UseQueryOptions<Dictionary<EmotionSummaryResponse>> => ({
    queryKey: [LocalitiesApi.name, 'getEmotionsSummaryByLocality', localityIds, intervals],
    queryFn: async (): Promise<Dictionary<EmotionSummaryResponse>> => {
        return fromPairs(
            await Promise.all(
                localityIds.map(async (localityId) => [
                    localityId,
                    await statisticsApi.getEmotionsSummary({
                        body: { intervals },
                        localityId,
                    }),
                ])
            )
        )
    },
})

export const visibleZoneOccupancyStatisticsByLocalityQuery = ({
    localityIds,
    intervals,
}: {
    localityIds: Array<number>
    intervals: Array<DatetimeInterval>
}): UseQueryOptions<Dictionary<ZoneOccupancySummaryResponse>> => ({
    queryKey: [StatisticApi.name, 'getZoneOccupancyStatisticsByLocality', localityIds, intervals],
    queryFn: async (): Promise<Dictionary<ZoneOccupancySummaryResponse>> => {
        return fromPairs(
            await Promise.all(
                localityIds.map(async (locality) => [
                    locality,
                    await statisticsApi.getZoneOccupancySummary({
                        body: { intervals, locality },
                    }),
                ])
            )
        )
    },
})

export const visibleMultipleLocalityZoneOccupancySessionsQuery = ({
    startingFrom,
    endingAt,
    localityIds,
}: {
    localityIds: number[]
    startingFrom: string
    endingAt: string
}): UseQueryOptions<Dictionary<ZoneOccupancySessionsResponse>> => ({
    queryKey: [StatisticApi.name, 'getMultipleLocalityZoneOccupancySessions', startingFrom, endingAt, localityIds],
    queryFn: async (): Promise<Dictionary<ZoneOccupancySessionsResponse>> => {
        return fromPairs(
            await Promise.all(
                localityIds.map(async (localityId) => [
                    localityId,
                    await statisticsApi.getZoneOccupancySessions({
                        body: { startingFrom, endingAt, localityId },
                    }),
                ])
            )
        )
    },
})

export const visibleQueueStatisticsByLocalityQuery = ({
    localityIds,
    intervals,
}: {
    localityIds: Array<number>
    intervals: Array<DatetimeInterval>
}): UseQueryOptions<Dictionary<QueueStatisticsResponse>> => ({
    queryKey: [StatisticApi.name, 'getQueueStatisticsByLocality', localityIds, intervals],
    queryFn: async (): Promise<Dictionary<QueueStatisticsResponse>> => {
        return fromPairs(
            await Promise.all(
                localityIds.map(async (locality) => [
                    locality,
                    await statisticsApi.getQueueStatistics({
                        body: {
                            intervals,
                            locality,
                        },
                    }),
                ])
            )
        )
    },
})

export class StatisticApi extends StatisticsApi {
    public async getEmotionsSummaryByLocality({
        localityIds,
        intervals,
    }: {
        localityIds: number[]
        intervals: DatetimeInterval[]
    }): Promise<Dictionary<EmotionSummaryResponse>> {
        return fromPairs(
            await Promise.all(
                localityIds.map(async (localityId) => [
                    localityId,
                    await this.getEmotionsSummary({
                        body: { intervals },
                        localityId,
                    }),
                ])
            )
        )
    }
}

export const visibleUsersQuery = (profile?: ProfileResponse): UseQueryOptions<UserListResponse> => ({
    enabled: profile !== undefined,
    queryKey: [UserManagementApi.name, 'getUsers', profile?.id],
    queryFn: async () => {
        if (profile?.role === Role.Administrator) {
            return await userApi.getUsers()
        }

        return await organizationApi.getOrganizationMembers({
            organizationId: profile!.organizationId!,
        })
    },
})
