import { mapValues } from 'lodash'
import { DateTime, Interval } from 'luxon'
import React, { useCallback, useMemo } from 'react'
import DateRangePicker, { Props as DateRangePickerProps } from 'react-bootstrap-daterangepicker'
import { useTranslation } from 'react-i18next'

import { useLocalizeDateTime, useTimezoneConfig } from '@helpers/timezoneConfig'

import { createDateRangePickerLocale, createDateRanges } from './dateRangePicker'

const isMidnightToMidnight = (interval: Interval): boolean =>
    interval.start.equals(interval.start.startOf('day')) && interval.end.equals(interval.end.startOf('day'))

const DateTimeIntervalPicker: React.FC<{
    isTimeDisabled?: boolean
    isSingleDay?: boolean
    interval: Interval
    onSubmit: (interval: Interval) => void
    now: DateTime
    children: JSX.Element
    timePickerIncrementInSeconds?: number
    parentEl?: HTMLElement | null
}> = ({
    now,
    interval,
    isTimeDisabled = false,
    isSingleDay = false,
    onSubmit,
    children,
    timePickerIncrementInSeconds = 60,
    parentEl = null,
}) => {
    const zone = useTimezoneConfig()
    const localize = useLocalizeDateTime()
    const { t } = useTranslation()

    const ranges = useMemo(
        () => mapValues(createDateRanges(now, zone, t), ([start, end]) => [start, new Date(end.getTime() - 1)]),
        [now, zone, t]
    )

    const handleDateRangePickerCallback: DateRangePickerProps['onCallback'] = useCallback(
        (startMoment, endMoment) => {
            let start = DateTime.fromJSDate(startMoment.toDate(), { zone })
            let end = DateTime.fromJSDate(endMoment.toDate(), { zone })

            const isPredefinedRange = Object.values(ranges).some(
                ([start, end]) =>
                    startMoment.toDate().getTime() === start.getTime() && endMoment.toDate().getTime() === end.getTime()
            )

            if (isPredefinedRange) {
                // Compensate for gdynusa-readability of pre-defined ranges
                end = end.plus({ milliseconds: 1 })
            } else if (isTimeDisabled || isMidnightToMidnight(Interval.fromDateTimes(start, end))) {
                start = start.startOf('day')
                end = end.startOf('day').plus({ days: 1 })
            }

            const newInterval = Interval.fromDateTimes(start, end).mapEndpoints((it) => it.toUTC())
            onSubmit(newInterval)
        },
        [zone, isTimeDisabled]
    )

    const isInvalidDate = useCallback(
        (momentDate: any) =>
            DateTime.fromJSDate(momentDate.toDate()).setZone('utc', {
                keepLocalTime: true,
            }) > now,
        [now]
    )

    const localizedInterval = interval.mapEndpoints(localize)

    const startDate = localizedInterval.start.toJSDate()
    const endDate = isTimeDisabled
        ? localizedInterval.end.minus({ millisecond: 1 }).toJSDate() // Ad-hoc gdynusa-readability
        : isMidnightToMidnight(localizedInterval)
        ? localizedInterval.end.minus({ day: 1 }).toJSDate() // Correction for intuitive midnight-to-midnight intervals
        : localizedInterval.end.toJSDate()

    return (
        <DateRangePicker
            key={interval.toISO()}
            initialSettings={{
                startDate,
                endDate,
                ranges,
                timePicker: !isTimeDisabled,
                timePicker24Hour: true,
                timePickerIncrement: timePickerIncrementInSeconds,
                autoUpdateInput: false,
                locale: createDateRangePickerLocale(t),
                isInvalidDate,
                singleDatePicker: isSingleDay,
                parentEl: parentEl ?? 'body',
            }}
            onCallback={handleDateRangePickerCallback}
        >
            {children}
        </DateRangePicker>
    )
}

export default DateTimeIntervalPicker
