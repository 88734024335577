import classNames from 'classnames'
import { sortBy, uniqueId } from 'lodash'
import { DateTime, Interval } from 'luxon'
import { FC, useMemo, useState, MutableRefObject, useRef } from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import { useMutation } from 'react-query'

import { LocalityNameModel, VisitBoundaryWithId } from '@api'

import { backgroundJobsApi, localityApi } from '@services'

import { useSuspendingQuery, visibleSceneDescriptionsQuery } from '@helpers/api'
import { formatInterval, Precision } from '@helpers/intervals'
import { useLocalizeDateTime } from '@helpers/timezoneConfig'
import { pickColumn } from '@helpers/utils'

import Heading from '@elements/Heading/Heading'

import AsyncButton from '@components/AsyncButton'
import BoundaryPicker from '@components/BoundaryPicker'
import DateTimeIntervalPicker from '@components/DateTimeIntervalPicker/DateTimeIntervalPicker'
import LoadingWrapper from '@components/LoadingWrapper'

import styles from './CopyStatisticsModal.module.scss'

const LocalityBoundaryPicker: FC<{
    locality: LocalityNameModel
    selectedBoundaries: Array<VisitBoundaryWithId>
    onBoundariesChange: (boundaries: Array<VisitBoundaryWithId>) => void
}> = ({ locality, selectedBoundaries, onBoundariesChange }) => {
    const { data: targetLocalityScenes } = useSuspendingQuery(
        localityApi.getLocalityScenes.query({
            localityId: locality.id,
        })
    )

    const { data: sceneDescriptions } = useSuspendingQuery(
        visibleSceneDescriptionsQuery(targetLocalityScenes.scenes.map(({ id }) => id) ?? [])
    )

    return (
        <>
            {selectedBoundaries.map((it) => it.name).join('; ')}
            <BoundaryPicker
                sceneConfiguration={sceneDescriptions}
                scenes={targetLocalityScenes}
                selectedBoundaries={selectedBoundaries}
                selectedLocality={locality}
                onBoundariesChange={onBoundariesChange}
            />
        </>
    )
}

const ModalContent: FC<{ locality: LocalityNameModel; modalRef: MutableRefObject<HTMLDivElement | null> }> = ({
    locality,
    modalRef,
}) => {
    const { data } = useSuspendingQuery(localityApi.getLocalityNames.query())
    const localities = useMemo(() => sortBy(data.localities, 'id'), [data])

    const [now] = useState(DateTime.utc())
    const [sourceInterval, setSourceInterval] = useState<Interval>(
        Interval.fromDateTimes(now.minus({ days: 14 }), now.minus({ days: 7 }))
    )
    const [targetInterval, setTargetInterval] = useState<Interval>(Interval.fromDateTimes(now.minus({ days: 7 }), now))
    const [targetLocalityBoundaries, setTargetLocalityBoundaries] = useState<Array<VisitBoundaryWithId>>([])

    const [trendLocality, setTrendLocality] = useState<LocalityNameModel | undefined>()
    const [trendLocalityBoundaries, setTrendLocalityBoundaries] = useState<Array<VisitBoundaryWithId>>([])

    const { mutate: scheduleCopying, status } = useMutation({
        mutationFn: backgroundJobsApi.scheduleBoundaryStatisticsCopying,
    })
    const localize = useLocalizeDateTime()

    const datePickerParent = modalRef.current?.parentElement

    return (
        <>
            <Heading>Copy statistics</Heading>

            <Form
                onSubmit={(e) => {
                    e.preventDefault()

                    const sourcePeriodStart = sourceInterval.start.set({
                        hour: targetInterval.start.hour,
                        minute: targetInterval.start.minute,
                        second: targetInterval.start.second,
                        millisecond: targetInterval.start.millisecond,
                    })

                    scheduleCopying({
                        body: {
                            targetLocalityId: locality.id,
                            targetBoundaryIds: pickColumn(targetLocalityBoundaries, 'id'),
                            sourcePeriodStartingFrom: sourcePeriodStart.toISO(),
                            sourcePeriodEndingAt: sourcePeriodStart.plus(targetInterval.length()).toISO(),
                            targetPeriodStartingFrom: targetInterval.start.toISO(),
                            targetPeriodEndingAt: targetInterval.end.toISO(),
                            ...(trendLocality !== undefined
                                ? {
                                      trendLocalityId: trendLocality.id,
                                      trendBoundaryIds: pickColumn(trendLocalityBoundaries, 'id'),
                                  }
                                : {}),
                        },
                    })
                }}
            >
                <Form.Group as={Row}>
                    <Form.Label column={true} sm={3}>
                        Target locality
                    </Form.Label>
                    <Col sm={9}>{locality.name}</Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column={true} sm={3}>
                        Target locality boundaries
                    </Form.Label>
                    <Col sm={9}>
                        <LoadingWrapper>
                            <LocalityBoundaryPicker
                                locality={locality}
                                selectedBoundaries={targetLocalityBoundaries}
                                onBoundariesChange={setTargetLocalityBoundaries}
                            />
                        </LoadingWrapper>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column={true} sm={3}>
                        Target interval
                    </Form.Label>
                    <Col sm={9}>
                        {datePickerParent && (
                            <DateTimeIntervalPicker
                                interval={targetInterval}
                                now={now}
                                parentEl={datePickerParent}
                                timePickerIncrementInSeconds={3600}
                                onSubmit={setTargetInterval}
                            >
                                <input
                                    className={classNames('btn', 'btn-secondary')}
                                    type="button"
                                    value={formatInterval(targetInterval.mapEndpoints(localize), Precision.HOUR)}
                                />
                            </DateTimeIntervalPicker>
                        )}
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column={true} sm={3}>
                        Start of source interval
                    </Form.Label>
                    <Col sm={9}>
                        {datePickerParent && (
                            <DateTimeIntervalPicker
                                interval={sourceInterval}
                                isSingleDay={true}
                                isTimeDisabled={true}
                                now={now}
                                parentEl={datePickerParent}
                                onSubmit={setSourceInterval}
                            >
                                <input
                                    className={classNames('btn', 'btn-secondary')}
                                    type="button"
                                    value={sourceInterval.start.toLocaleString(DateTime.DATE_SHORT)}
                                />
                            </DateTimeIntervalPicker>
                        )}
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column={true} sm={3}>
                        Trend locality
                    </Form.Label>
                    <Col sm={9}>
                        <Form.Control
                            as="select"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setTrendLocality(localities.find((it) => it.id.toString() === e.target.value))
                            }
                        >
                            <option key="" value="">
                                -
                            </option>
                            {localities.map((it) => (
                                <option key={it.id} value={it.id}>
                                    #{it.id} - {it.name}
                                </option>
                            ))}
                        </Form.Control>
                    </Col>
                </Form.Group>
                {trendLocality && (
                    <Form.Group as={Row}>
                        <Form.Label column={true} sm={3}>
                            Trend locality boundaries
                        </Form.Label>
                        <Col sm={9}>
                            <LoadingWrapper>
                                <LocalityBoundaryPicker
                                    locality={trendLocality}
                                    selectedBoundaries={trendLocalityBoundaries}
                                    onBoundariesChange={setTrendLocalityBoundaries}
                                />
                            </LoadingWrapper>
                        </Col>
                    </Form.Group>
                )}
                <Form.Group as={Row}>
                    <Col sm={{ span: 9, offset: 3 }}>
                        <AsyncButton status={status} text="Submit" />
                    </Col>
                </Form.Group>
            </Form>
        </>
    )
}

const CopyStatisticsModal: FC<{ onClose: () => void; locality: LocalityNameModel }> = ({ onClose, locality }) => {
    const id = useMemo(uniqueId, [])
    const modalRef = useRef<HTMLDivElement | null>(null)

    return (
        <Modal animation={false} centered={false} className={styles.modal} id={id} show={true} onHide={onClose}>
            <Modal.Body ref={modalRef}>
                <LoadingWrapper>
                    <ModalContent locality={locality} modalRef={modalRef} />
                </LoadingWrapper>
            </Modal.Body>
        </Modal>
    )
}

export default CopyStatisticsModal
